@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* || VARIABLES */

:root {
  /* COLORS */
  /* --BGCOLOR: #f0ebf8; */
  --BGCOLOR: #fffdd0;
  
  /* --BORDER-COLOR: #FFF;
  --COLOR: #FFF;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: whitesmoke;
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #FFF;
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #F00; */
}

body {
  /* font-family: 'Nunito', sans-serif;
  min-height: 100vh; */
  /* background-color: var(--BGCOLOR); */
   /* color: var(--COLOR); */
  background-image: url('./img/sun-tornado.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  /*background-position: top right;
  background-blend-mode: multiply; */
}

.solarEnergy {
  fill-opacity: 0.65;
  fill: #FFBF00;
	stroke-width: 2px;
	opacity: 1;
  stroke: #000000;
  stroke-opacity: 1;
}

.windEnergy {
  fill-opacity: 0.65;
  fill: #f3ebe8;
	stroke-width: 2px;
	opacity: 1;
  stroke: #000000;
  stroke-opacity: 1;
}

.biomassEnergy {
  fill-opacity: 0.65;
  fill: #947b4f;
	stroke-width: 2px;
	opacity: 1;
  stroke: #000000;
  stroke-opacity: 1;
}

.hydroPower {
  fill-opacity: 0.65;
  fill: #1e2f97;
	stroke-width: 2px;
	opacity: 1;
  stroke: #000000;
  stroke-opacity: 1;
}

.controlStyle {
	width: 36px;
	height: 36px;
  cursor: pointer;
}

.bgImage {
  background-image: url('./img/sun-tornado.svg');
  background-repeat: no-repeat;
}

